import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';

export const getProducts = createAsyncThunk(
	'product/getProducts',
	async (payload, { getState }) => {
		const { loading, params } = getState().product;
		const { userData } = getState().auth;

		if (loading) {
			try {
				const response = await apiClient.get('/api/fetchProducts', {
					params: {
						...payload,
						...params,
						status: ['active', 'inactive'],
						shop: userData?.shop?._id || '',
					},
				});
				return { data: response.data };
			} catch (error) {
				console.log('error /getProducts', error);
			}
		}
	},
);

export const submitProduct = createAsyncThunk(
	'product/submitProduct',
	async (params, { getState }) => {
		const { loading } = getState().product;

		if (loading) {
			try {
				const response = await apiClient.post('/api/submitProduct', params);
				return { data: response.data };
			} catch (error) {
				console.log('error /submitProduct', error);
			}
		}
	},
);

export const editProduct = createAsyncThunk(
	'product/editProduct',
	async (params, { getState }) => {
		const { loading } = getState().product;

		if (loading) {
			try {
				const response = await apiClient.post('/api/updateProduct', params);
				console.log('response.data', response.data);
				return { data: response.data };
			} catch (error) {
				console.log('error /editProduct', error);
			}
		}
	},
);

export const submitSubProduct = createAsyncThunk(
	'product/submitSubProduct',
	async (params, { getState }) => {
		const { loading } = getState().product;

		if (loading) {
			try {
				const response = await apiClient.post('/api/submitSubProduct', params);
				return { data: response.data };
			} catch (error) {
				console.log('error /submitSubProduct', error);
			}
		}
	},
);

export const getProductDetails = createAsyncThunk(
	'product/getProductDetails',
	async (params, { getState }) => {
		const { loading } = getState().product;

		if (loading) {
			try {
				const response = await apiClient.get(`/api/fetchProduct/${params}`);
				return { data: response.data };
			} catch (error) {
				console.log('error /getProductDetails', error);
			}
		}
	},
);

export const productSlice = createSlice({
	name: 'product',
	initialState: {
		// cart: [],
		params: {
			page: 1,
			limit: 9,
			sort: { createdAt: -1 },
			category: '',
		},
		products: [],
		loading: false,
		uploadUrl: {},
		product: {},
	},
	reducers: {
		setProductDetails: (state, action) => {
			state.product = action.payload;
		},
		setParams: (state, action) => {
			state.params = {
				...state.params,
				...action.payload,
			};
		},
	},
	extraReducers: {
		[getProducts.pending]: (state) => {
			state.loading = true;
		},
		[getProducts.fulfilled]: (state, action) => {
			state.products = action.payload.data.products;
			state.totalProducts = action.payload.data.count;
			state.loading = false;
		},
		[getProducts.rejected]: (state) => {
			state.loading = false;
		},
		[submitProduct.pending]: (state) => {
			state.loading = true;
		},
		[submitProduct.fulfilled]: (state, action) => {
			state.product = {};
			// state.product = action.payload?.data?.product;
			state.loading = false;
		},
		[submitProduct.rejected]: (state) => {
			state.loading = false;
		},
		[submitSubProduct.pending]: (state) => {
			state.loading = true;
		},
		[submitSubProduct.fulfilled]: (state, action) => {
			state.product = action.payload.data?.product;
			state.loading = false;
		},
		[submitSubProduct.rejected]: (state) => {
			state.loading = false;
		},
		[editProduct.pending]: (state) => {
			state.loading = true;
		},
		[editProduct.fulfilled]: (state, action) => {
			state.product = action.payload.data?.product;
			state.loading = false;
		},
		[editProduct.rejected]: (state) => {
			state.loading = false;
		},
		[getProductDetails.pending]: (state) => {
			state.loading = true;
		},
		[getProductDetails.fulfilled]: (state, action) => {
			state.product = action.payload.data.product;
			state.loading = false;
		},
		[getProductDetails.rejected]: (state) => {
			state.loading = false;
		},
	},
});

export const { setProductDetails, setParams } = productSlice.actions;

export default productSlice.reducer;
