import axios from 'axios';

const prod = process.env.NODE_ENV === 'production';

const token = localStorage.getItem('token');

const apiClient = axios.create({
	baseURL: prod ? 'https://nomady.mn/' : 'http://localhost:8061',
	timeout: 1000,
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	},
});

export default apiClient;

export const s3Upload = async (file) => {
	const response = await apiClient.get('/api/getUploadUrl', {
		params: { fileType: file.type, fileName: file.name },
	});
	const { url } = response.data;

	const requestOptions = {
		method: 'PUT',
		body: file,
		redirect: 'follow',
		headers: {
			'Content-Type': file.type,
		},
	};

	return fetch(url, requestOptions)
		.then((res) => res)
		.catch((error) => console.log('error', error));
};
