// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt';

const { jwt } = useJwt({
	loginEndpoint: '/api/login/merchant',
	registerEndpoint: '/api/register',
	refreshEndpoint: '/api/refresh-token',
	logoutEndpoint: '/api/logout',
	storageTokenKeyName: 'token',
});

export default jwt;
