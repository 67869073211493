// ** Reducers Imports
// import todo from '@src/views/apps/todo/store';
// import chat from '@src/views/apps/chat/store';
// import users from '@src/views/apps/user/store';
// import email from '@src/views/apps/email/store';
// import invoice from '@src/views/apps/invoice/store';
// import calendar from '@src/views/apps/calendar/store';
// import ecommerce from '@src/views/apps/ecommerce/store';
// import dataTables from '@src/views/tables/data-tables/store';
// import permissions from '@src/views/apps/roles-permissions/store';
// import product from '@src/views/products/store';
import auth from './authentication';
import layout from './layout';
import navbar from './navbar';
import product from '../views/Products/store';

const rootReducer = {
	auth,
	// todo,
	// chat,
	// email,
	// users,
	navbar,
	layout,
	// invoice,
	// calendar,
	// ecommerce,
	// dataTables,
	// permissions,
	product,
};

export default rootReducer;
